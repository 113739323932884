import React, { useEffect, useState } from "react";
import { getAllDocsSnap } from "../../Firebase/Firebase.utils";

export default function AverageDealsPerAgent() {
  const [tenantRepDeals, setTenantRepDeals] = useState([]);
  const [landlordRepDeals, setLandlordRepDeals] = useState([]);
  const [investSalesDeals, setInvestSalesDeals] = useState([]);
  const [agents, setAgents] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [departmentStats, setDepartmentStats] = useState([]);
  const [overallStats, setOverallStats] = useState({});
  const [pipelineStats, setPipelineStats] = useState({});

  // Fetch all users and filter agents
  useEffect(() => {
    const fetchUsers = async () => {
      await getAllDocsSnap("users", (data) => {
        setUsers(data);
        const agentsData = data.filter((user) => user.roles.includes("agent"));
        setAgents(agentsData);
      });
    };
    fetchUsers();
  }, []);

  // Fetch all deals data
  useEffect(() => {
    const fetchTenantRepDeals = async () => {
      await getAllDocsSnap("tenantRep", (data) => {
        setTenantRepDeals(data);
      });
    };

    const fetchLandlordRepDeals = async () => {
      await getAllDocsSnap("landlordRep", (data) => {
        setLandlordRepDeals(data);
      });
    };

    const fetchInvestSalesDeals = async () => {
      await getAllDocsSnap("listings", (data) => {
        setInvestSalesDeals(data);
        setIsLoading(false);
      });
    };

    fetchTenantRepDeals();
    fetchLandlordRepDeals();
    fetchInvestSalesDeals();
  }, []);

  // Process data to calculate averages when all data is loaded
  useEffect(() => {
    if (isLoading) return;

    // Helper function to get agent's department
    const getDepartment = (email) => {
      const user = users.find((user) => user.email === email);
      return user?.department
        ? capitalizeString(user.department)
        : "No Department";
    };

    // Helper function to capitalize strings
    const capitalizeString = (str) => {
      return str
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    };

    // Helper function to check if deal is in 30-90% pipeline
    const isInPipeline = (deal) => {
      // Extract percentage from string like "30%" or check numeric value
      const status = deal.pipeline_status;

      if (!status) return false;

      // Handle percentage strings like "30%"
      if (typeof status === "string" && status.endsWith("%")) {
        const percent = parseInt(status);
        return percent >= 30 && percent <= 90;
      }

      // Handle numeric values
      if (typeof status === "number") {
        return status >= 30 && status <= 90;
      }

      return false;
    };

    // Process deals into a standard format with department info
    const processedDeals = [
      ...tenantRepDeals.map((deal) => ({
        type: "Tenant Representation",
        accountName: deal.tenantName,
        lead: deal.team_lead,
        department: getDepartment(deal.team_lead),
        teamMembers: deal.team_members || [],
        pipelineStatus: deal.pipeline_status,
        inPipeline: isInPipeline(deal),
      })),
      ...landlordRepDeals.map((deal) => ({
        type: "Landlord Representation",
        accountName: deal.landlordName || deal.accountName,
        lead: deal.team_lead,
        department: getDepartment(deal.team_lead),
        teamMembers: deal.team_members || [],
        pipelineStatus: deal.pipeline_status,
        inPipeline: isInPipeline(deal),
      })),
      ...investSalesDeals.map((deal) => ({
        type: "Investment Sales",
        accountName: deal.propertyName || deal.listingName,
        lead: deal.team_lead,
        department: getDepartment(deal.team_lead),
        teamMembers: deal.team_members || [],
        pipelineStatus: deal.pipeline_status,
        inPipeline: isInPipeline(deal),
      })),
    ];

    // Filter deals in pipeline (30-90%)
    const pipelineDeals = processedDeals.filter((deal) => deal.inPipeline);

    // Count agents per department
    const departmentAgentCounts = {};
    agents.forEach((agent) => {
      const dept = agent.department
        ? capitalizeString(agent.department)
        : "No Department";
      departmentAgentCounts[dept] = (departmentAgentCounts[dept] || 0) + 1;
    });

    // Count deals per department
    const departmentDealCounts = {};
    processedDeals.forEach((deal) => {
      const dept = deal.department;
      departmentDealCounts[dept] = (departmentDealCounts[dept] || 0) + 1;
    });

    // Count pipeline deals per department
    const departmentPipelineCounts = {};
    pipelineDeals.forEach((deal) => {
      const dept = deal.department;
      departmentPipelineCounts[dept] =
        (departmentPipelineCounts[dept] || 0) + 1;
    });

    // Calculate stats by department
    const stats = Object.keys(departmentAgentCounts).map((dept) => {
      const agentCount = departmentAgentCounts[dept];
      const dealCount = departmentDealCounts[dept] || 0;
      const pipelineCount = departmentPipelineCounts[dept] || 0;
      const avgDealsPerAgent =
        agentCount > 0 ? (dealCount / agentCount).toFixed(2) : 0;
      const avgPipelinePerAgent =
        agentCount > 0 ? (pipelineCount / agentCount).toFixed(2) : 0;

      return {
        department: dept,
        agentCount,
        dealCount,
        pipelineCount,
        avgDealsPerAgent,
        avgPipelinePerAgent,
      };
    });

    // Calculate overall stats
    const totalAgents = agents.length;
    const totalDeals = processedDeals.length;
    const totalPipelineDeals = pipelineDeals.length;
    const overallAvg =
      totalAgents > 0 ? (totalDeals / totalAgents).toFixed(2) : 0;
    const overallPipelineAvg =
      totalAgents > 0 ? (totalPipelineDeals / totalAgents).toFixed(2) : 0;

    // Sort departments by average deals per agent (highest first)
    stats.sort((a, b) => b.avgDealsPerAgent - a.avgDealsPerAgent);

    setDepartmentStats(stats);
    setOverallStats({
      totalAgents,
      totalDeals,
      overallAvg,
      totalPipelineDeals,
      overallPipelineAvg,
    });
  }, [
    isLoading,
    tenantRepDeals,
    landlordRepDeals,
    investSalesDeals,
    agents,
    users,
  ]);

  if (isLoading) {
    return (
      <div className="p-4 bg-white rounded-lg mt-4">
        <div className="header">Loading...</div>
      </div>
    );
  }

  return (
    <div className="p-4 bg-white rounded-lg mt-4">
      <div className="header mb-4">Average Deals Per Agent</div>

      {/* Overall stats */}
      <div className="mb-6">
        <h2 className="text-lg font-semibold mb-2">Overall Stats</h2>
        <div className="grid grid-cols-5 gap-4 bg-gray-50 p-3 rounded-md">
          <div className="text-center">
            <div className="text-gray-500 text-sm">Total Agents</div>
            <div className="font-bold text-xl">{overallStats.totalAgents}</div>
          </div>
          <div className="text-center">
            <div className="text-gray-500 text-sm">Total Deals</div>
            <div className="font-bold text-xl">{overallStats.totalDeals}</div>
          </div>
          <div className="text-center">
            <div className="text-gray-500 text-sm">Avg Deals/Agent</div>
            <div className="font-bold text-xl">{overallStats.overallAvg}</div>
          </div>
          <div className="text-center">
            <div className="text-gray-500 text-sm">Pipeline Deals (30-90%)</div>
            <div className="font-bold text-xl">
              {overallStats.totalPipelineDeals}
            </div>
          </div>
          <div className="text-center">
            <div className="text-gray-500 text-sm">Avg Pipeline/Agent</div>
            <div className="font-bold text-xl">
              {overallStats.overallPipelineAvg}
            </div>
          </div>
        </div>
      </div>

      {/* Department stats */}
      <div>
        <h2 className="text-lg font-semibold mb-2">Stats by Department</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-2 px-4 text-left">Department</th>
                <th className="py-2 px-4 text-center">Agents</th>
                <th className="py-2 px-4 text-center">Deals</th>
                <th className="py-2 px-4 text-center">Avg Deals/Agent</th>
                <th className="py-2 px-4 text-center">
                  Pipeline Deals (30-90%)
                </th>
                <th className="py-2 px-4 text-center">Avg Pipeline/Agent</th>
              </tr>
            </thead>
            <tbody>
              {departmentStats.map((stat, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : ""}>
                  <td className="py-2 px-4">{stat.department}</td>
                  <td className="py-2 px-4 text-center">{stat.agentCount}</td>
                  <td className="py-2 px-4 text-center">{stat.dealCount}</td>
                  <td className="py-2 px-4 text-center font-semibold">
                    {stat.avgDealsPerAgent}
                  </td>
                  <td className="py-2 px-4 text-center">
                    {stat.pipelineCount}
                  </td>
                  <td className="py-2 px-4 text-center font-semibold">
                    {stat.avgPipelinePerAgent}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
