import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LeasingAccountCountCard from "./LeasingAccountCountCard";
import { Barchartv1 } from "../../components/ui/BarChartv1";
import { useNavigate } from "react-router-dom";
import { queryAllDocsByField } from "../../Firebase/Firebase.utils";

export default function LeasingDashBoard() {
  const currentUser = useSelector((state) => state.userObject);
  const [leasingAccounts, setLeasingAccounts] = React.useState([]);
  const [landLordRepAccounts, setLandLordRepAccounts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const getLeasingAccounts = async () => {
      const data = await queryAllDocsByField(
        "tenantRep",
        "team_members",
        "array-contains",
        currentUser.email,
        // "arielle.roleda@leechiu.com",
        (leasingAccounts) => {
          setLeasingAccounts(leasingAccounts);
        }
      );
    };
    getLeasingAccounts();
  }, []);

  useEffect(() => {
    const getLeasingAccounts = async () => {
      const data = await queryAllDocsByField(
        "landlordRepRequirements",
        "team_members",
        "array-contains",
        currentUser.email,
        // "arielle.roleda@leechiu.com",
        (leasingAccounts) => {
          setLandLordRepAccounts(leasingAccounts);
          setIsLoading(false);
        }
      );
    };
    getLeasingAccounts();
  }, []);

  const mappedLeasingAccounts = leasingAccounts.map((account) => {
    return {
      ...account,
      accountType: "Tenant Rep",
    };
  });

  const mappedLandLordRepAccounts = landLordRepAccounts.map((account) => {
    return {
      ...account,
      accountType: "Landlord Rep",
    };
  });

  const combinedAccounts = [
    ...mappedLeasingAccounts,
    ...mappedLandLordRepAccounts,
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //count the number of accounts per status
  const countAccountsPerStatus = (status) => {
    return combinedAccounts.filter(
      (account) => account.pipeline_status === status
    ).length;
  };

  const accountsWithoutStatus = combinedAccounts.filter(
    (account) => account.pipeline_status === undefined
  );

  const dataTemplate = [
    { percentage: "10%", accounts: countAccountsPerStatus("10%") },
    { percentage: "30%", accounts: countAccountsPerStatus("30%") },
    { percentage: "50%", accounts: countAccountsPerStatus("50%") },
    { percentage: "90%", accounts: countAccountsPerStatus("90%") },
    { percentage: "100%", accounts: countAccountsPerStatus("100%") },
    // { percentage: "Not Set", accounts: accountsWithoutStatus.length },
  ];

  console.log(combinedAccounts);

  return (
    <div>
      <LeasingAccountCountCard data={combinedAccounts} />
      <div className="h-auto w-96 mt-4 bg-white overflow-hidden rounded-lg">
        <Barchartv1 data={dataTemplate} />
      </div>
      <div className="w-96 mt-4">
        {currentUser.roles.includes("cl admin") && (
          <div className="bg-white p-2 rounded-xl text-gray-600 text-sm">
            <div className="font-bold">Quick Links</div>
            <div className="mt-4 underline font-bold text-leechiuOrange uppercase">
              <Link to="/dashboard/cl-pipeline">CL Pipeline Report</Link>
            </div>
            <div></div>
          </div>
        )}
      </div>
    </div>
  );
}
