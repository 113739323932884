import React, { useEffect, useState } from "react";
import ISAccountCountCard from "./ISAccountCountCard";
import { Barchartv1 } from "../../components/ui/BarChartv1";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { queryAllDocsByField } from "../../Firebase/Firebase.utils";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import SpinnerPage from "../../components/SpinnerPage";
import {
  TrendingUp,
  BarChart3,
  Users,
  Activity,
  ArrowRight,
} from "lucide-react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";
import { ChartContainer } from "../../components/ui/chart";

export default function ISDashboard() {
  const currentUser = useSelector((state) => state.userObject);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [metrics, setMetrics] = useState({
    total: 0,
    activeDeals: 0,
    potentialRevenue: 0,
  });

  useEffect(() => {
    const fetchAccounts = async () => {
      await queryAllDocsByField(
        "listings",
        "team_members",
        "array-contains",
        currentUser.email,
        (data) => {
          setAccounts(data);

          // Calculate metrics
          const potentialRevenue = data.reduce((acc, curr) => {
            const fee = parseFloat(curr.potential_fee);
            return acc + (isNaN(fee) ? 0 : fee);
          }, 0);

          const activeDeals = data.filter(
            (account) =>
              account.pipeline_status === "50%" ||
              account.pipeline_status === "90%"
          ).length;

          setMetrics({
            total: data.length,
            activeDeals,
            potentialRevenue,
          });

          setIsLoading(false);
        }
      );
    };
    fetchAccounts();
  }, [currentUser.email]);

  const countAccountsPerStatus = (status) => {
    return accounts.filter((account) => account.pipeline_status === status)
      .length;
  };

  const chartData = [
    {
      percentage: "10%",
      accounts: countAccountsPerStatus("10%"),
      fill: "#E0E7FF",
    },
    {
      percentage: "30%",
      accounts: countAccountsPerStatus("30%"),
      fill: "#C7D2FE",
    },
    {
      percentage: "50%",
      accounts: countAccountsPerStatus("50%"),
      fill: "#A5B4FC",
    },
    {
      percentage: "90%",
      accounts: countAccountsPerStatus("90%"),
      fill: "#818CF8",
    },
    {
      percentage: "100%",
      accounts: countAccountsPerStatus("100%"),
      fill: "#6366F1",
    },
  ];

  const chartConfig = {
    accounts: {
      label: "Accounts",
      color: "#6366F1",
    },
  };

  const isAdmin =
    currentUser.department === "Investment Sales" ||
    (currentUser.roles && currentUser.roles.includes("is admin"));

  if (isLoading) {
    return <SpinnerPage />;
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-8">
        <div>
          <h1 className="text-3xl font-bold text-gray-800 mb-1">
            Investment Sales Dashboard
          </h1>
          <p className="text-gray-500">
            Welcome back,{" "}
            {currentUser.displayName || currentUser.email.split("@")[0]}
          </p>
        </div>
        <div className="mt-4 md:mt-0 flex space-x-2">
          <button className="px-4 py-2 bg-indigo-600 text-white rounded-lg text-sm font-medium hover:bg-indigo-700 transition-colors flex items-center">
            <Users className="h-4 w-4 mr-2" />
            Add Account
          </button>
        </div>
      </div>

      {/* Stats Cards Row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="bg-gradient-to-br from-indigo-50 to-white p-6 rounded-xl shadow-sm border border-indigo-100">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm text-gray-500 mb-1">Total Accounts</p>
              <h3 className="text-3xl font-bold text-gray-900">
                {metrics.total}
              </h3>
            </div>
            <div className="bg-indigo-100 p-2 rounded-full">
              <Users className="h-6 w-6 text-indigo-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-xs text-green-600 font-medium">
            <TrendingUp className="h-3 w-3 mr-1" />
            <span>Up 12% from last month</span>
          </div>
        </div>

        <div className="bg-gradient-to-br from-emerald-50 to-white p-6 rounded-xl shadow-sm border border-emerald-100">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm text-gray-500 mb-1">Active Deals</p>
              <h3 className="text-3xl font-bold text-gray-900">
                {metrics.activeDeals}
              </h3>
            </div>
            <div className="bg-emerald-100 p-2 rounded-full">
              <Activity className="h-6 w-6 text-emerald-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-xs text-green-600 font-medium">
            <TrendingUp className="h-3 w-3 mr-1" />
            <span>Up 8% from last month</span>
          </div>
        </div>

        <div className="bg-gradient-to-br from-amber-50 to-white p-6 rounded-xl shadow-sm border border-amber-100">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm text-gray-500 mb-1">Potential Revenue</p>
              <h3 className="text-3xl font-bold text-gray-900">
                ₱{(metrics.potentialRevenue / 1000000).toFixed(2)}M
              </h3>
            </div>
            <div className="bg-amber-100 p-2 rounded-full">
              <BarChart3 className="h-6 w-6 text-amber-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-xs text-green-600 font-medium">
            <TrendingUp className="h-3 w-3 mr-1" />
            <span>Up 15% from last month</span>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        <Card className="lg:col-span-2 border-0 shadow-md rounded-xl overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-indigo-50 to-white border-b pb-2">
            <CardTitle className="text-lg font-medium flex items-center">
              <BarChart3 className="h-5 w-5 mr-2 text-indigo-600" />
              Pipeline Status
            </CardTitle>
          </CardHeader>
          <CardContent className="p-4">
            <div className="flex flex-col">
              <div className="text-lg font-medium text-indigo-600 mb-1">
                Account by Status
              </div>
              <div className="text-sm text-gray-500 mb-4">
                Number of Accounts per Pipeline Status
              </div>
              <div className="w-full h-40">
                <ChartContainer config={chartConfig} className="max-h-40">
                  <BarChart
                    data={chartData}
                    margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="percentage" fontSize={10} tickMargin={5} />
                    <YAxis fontSize={10} />
                    <Tooltip />
                    <Bar
                      dataKey="accounts"
                      fill="#6366F1"
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ChartContainer>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="border-0 shadow-md rounded-xl overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-indigo-50 to-white border-b pb-2">
            <CardTitle className="text-lg font-medium flex items-center">
              <Users className="h-5 w-5 mr-2 text-indigo-600" />
              Account Overview
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <ISAccountCountCard />
          </CardContent>
        </Card>
      </div>

      {/* Quick Links Section */}
      {isAdmin && (
        <Card className="border-0 shadow-md rounded-xl overflow-hidden">
          <CardHeader className="bg-gradient-to-r from-indigo-50 to-white border-b pb-2">
            <CardTitle className="text-lg font-medium flex items-center">
              <Activity className="h-5 w-5 mr-2 text-indigo-600" />
              Quick Actions
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <Link
                to="/dashboard/is-pipeline"
                className="flex items-center p-4 rounded-lg bg-white border border-gray-100 hover:border-indigo-200 hover:bg-indigo-50 transition-all shadow-sm"
              >
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    Pipeline per Agent
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    View detailed agent breakdown
                  </p>
                </div>
                <ArrowRight className="h-4 w-4 text-indigo-500" />
              </Link>

              <Link
                to="/all-listings"
                className="flex items-center p-4 rounded-lg bg-white border border-gray-100 hover:border-indigo-200 hover:bg-indigo-50 transition-all shadow-sm"
              >
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    All Listings
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    Manage all active listings
                  </p>
                </div>
                <ArrowRight className="h-4 w-4 text-indigo-500" />
              </Link>

              <Link
                to="/reports"
                className="flex items-center p-4 rounded-lg bg-white border border-gray-100 hover:border-indigo-200 hover:bg-indigo-50 transition-all shadow-sm"
              >
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-900">Reports</p>
                  <p className="text-xs text-gray-500 mt-1">
                    Generate performance reports
                  </p>
                </div>
                <ArrowRight className="h-4 w-4 text-indigo-500" />
              </Link>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
