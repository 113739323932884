import React from "react";
import { useState, useEffect } from "react";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import { getDocDataSnap, setDocData } from "../../Firebase/Firebase.utils";
import { useSelector } from "react-redux";
import Modal from "../../marketing/Modal";
import DeleteListingForm from "./DeleteListingForm";
import { useSearchParams } from "react-router-dom";
import SummaryComponent from "./SummaryComponent";
import DetailedInfo from "./DetailedInfo";
import DueDiligence from "./DueDiligence";
import Photos from "./Photos";
import TaskManagement from "../Tasks/TaskManagement";
import MeetingNotes from "./MeetingNotes";
import FlyerViewer from "./FlyerViewer";
import Marketing from "./Marketing";
import Comparables from "./Comparables";

function ListingView() {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [listing, setListing] = useState({});
  const [editMode, setEditMode] = useState(false); // Add state for edit mode
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [activeLink, setActiveLink] = useState("Summary Info");
  const [searchParams, setSearchParams] = useSearchParams();

  const activeLinkParam = searchParams.get("link");

  useEffect(() => {
    if (activeLinkParam) {
      setActiveLink(activeLinkParam);
    }
  }, [activeLinkParam]);

  const links = [
    "Summary Info",
    "Detailed Info",
    "Due Diligence",
    "Comparables",
    "Photos",
    "Task Management",
    "Meeting Notes",
    "Flyer",
    "Marketing",
    "Closing",
    "Web View",
  ];

  useEffect(() => {
    const getListing = async () => {
      const listing = await getDocDataSnap("listings", id, (data) => {
        setListing(data);
        setFormData(data);
        setIsLoading(false);
      });
    };

    getListing();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value === "" ? "" : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    if (editMode) {
      const docObject = {
        ...formData,
      };
      await setDocData("listings", id, docObject);
      setEditMode(false);
    }
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const MainCard = () => {
    return (
      <>
        <div className="flex items-center justify-between text-xs text-gray-600">
          <Link to="/all-listings" className=" hover:text-orange-800 underline">
            Back to all listings
          </Link>
          <div
            className="text-xs underline text-gray-600 cursor-pointer"
            onClick={handleDelete}
          >
            Delete this listing
          </div>
        </div>

        {showModal && (
          <Modal>
            <DeleteListingForm id={id} setShowModal={setShowModal} />
          </Modal>
        )}

        <div className="bubble relative text-gray-600">
          <div className=" text-sm uppercase font-bold">
            {formData.property_name}
          </div>
          <div className="">
            <div className="uppercase text-sm  text-right bg-blue-100 rounded py-1 px-3 w-fit absolute top-0 right-0">
              {formData.listing_type}
            </div>
            <div className="text-xs text-gray-500">{formData.address}</div>
          </div>
          <div className="mt-2 text-xs flex items-center justify-normal gap-2 flex-wrap">
            {links.map((link) => (
              <div
                className={
                  link === activeLink ? "active-pill" : "inactive-pill"
                }
                onClick={() => handleActiveLink(link)}
              >
                {link}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const handleActiveLink = (link) => {
    setSearchParams({ link });
    setActiveLink(link);
  };

  return (
    <div>
      {isLoading ? (
        "loading "
      ) : (
        <div className="flex flex-col gap-4 max-w-5xl">
          <MainCard />
          <div>
            {activeLinkParam === "Summary Info" && (
              <SummaryComponent listing={listing} />
            )}

            {activeLinkParam === "Detailed Info" && (
              <DetailedInfo listing={listing} />
            )}

            {activeLinkParam === "Due Diligence" && <DueDiligence />}
            {activeLinkParam === "Photos" && <Photos />}

            {activeLinkParam === "Task Management" && (
              <TaskManagement listing={listing} />
            )}

            {activeLinkParam === "Meeting Notes" && <MeetingNotes />}
            {activeLinkParam === "Flyer" && <FlyerViewer listing={listing} />}
            {activeLinkParam === "Marketing" && <Marketing />}
            {activeLinkParam === "Closing" && <div>Closing</div>}
            {activeLinkParam === "Web View" && <div>Web View</div>}
            {activeLinkParam === "Comparables" && (
              <div>
                <Comparables />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ListingView;
