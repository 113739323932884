import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ISDashboard from "./ISDashboard";
import LeasingDashBoard from "./LeasingDashBoard";
import AverageDealsPerAgent from "./AverageDealsPerAgent";

function Dashboard() {
  const currentUser = useSelector((state) => state.userObject);

  return (
    <div className="w-full rounded-lg p-8">
      <div className="text-leechiuBlue text-xl font-semibold">
        Welcome {currentUser.displayName}
      </div>

      {currentUser.department === "Investment Sales" && (
        <div className="mt-4">
          <ISDashboard />
        </div>
      )}

      {currentUser.department === "Commercial Leasing" && <LeasingDashBoard />}

      <AverageDealsPerAgent />
    </div>
  );
}

export default Dashboard;
