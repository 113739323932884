import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import InputBox from "../../components/InputBox";
import { useSelector } from "react-redux";
import { getDocDataSnap, setDocData } from "../../Firebase/Firebase.utils";
import JoditEditor from "jodit-react";
import HTMLReactParser from "html-react-parser";
import { motion } from "framer-motion";

function MeetingNotes() {
  const { id } = useParams();
  const [formData, setFormData] = React.useState({});
  const [listing, setListing] = React.useState({});
  const [content, setContent] = React.useState("");
  const [showEditor, setShowEditor] = React.useState(false);
  const [meetingNotes, setMeetingNotes] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const editor = React.useRef(null);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const res = await getDocDataSnap("listings", id, (data) => {
          setListing(data);
          setMeetingNotes(data.meeting_notes);
          setIsLoading(false);
        });
      } catch (error) {}
    };
    fetchListing();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let docObject = {};

    if (
      formData.id === undefined ||
      formData.id === null ||
      formData.id === ""
    ) {
      docObject = {
        meeting_notes: [
          ...meetingNotes,
          {
            date: formData.date,
            notes: content,
            created_by: currentUser.email,
            created_at: new Date(),
            id: Math.floor(Math.random() * 100000),
          },
        ],
      };
    } else {
      docObject = {
        meeting_notes: [
          ...meetingNotes,
          {
            ...formData,
            notes: content,
            edited_by: currentUser.email,
            edited_at: new Date(),
          },
        ],
      };
    }
    const setDocDataSnap = async () => {
      try {
        const res = await setDocData("listings", id, docObject);
      } catch (error) {}
    };
    setDocDataSnap();
    setContent("");
    setShowEditor(false);
    setFormData({});
    setEditMode(false);
  };

  const handleContent = (newContent) => {
    setContent(newContent);
  };

  const handleShowEditor = () => {
    setShowEditor(!showEditor);
  };

  const handleDelete = (e, noteId) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this note?")) {
      const docObject = {
        meeting_notes: listing.meeting_notes.filter((note) => {
          return note.id !== noteId;
        }),
      };

      const setDocDataSnap = async () => {
        try {
          const res = await setDocData("listings", id, docObject);
        } catch (error) {}
      };
      setDocDataSnap();
    } else {
      return;
    }
  };

  const handleEdit = (e, note) => {
    e.preventDefault();
    setEditMode(true);
    const newMeetingNotes = meetingNotes.filter((n) => n.id !== note.id);
    setMeetingNotes(newMeetingNotes);
    setContent(note.notes);
    setShowEditor(true);
    setFormData(note);
  };

  const meetingNotesArranged = meetingNotes.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }}>
      <button
        className="bg-leechiuBlue text-white p-2 rounded mb-4 text-xs"
        onClick={handleShowEditor}
      >
        {showEditor ? "Hide Editor" : "Add Meeting Notes"}
      </button>

      {showEditor && (
        <div className="mb-8  bg-gray-100 p-2 border ">
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <label className="mb-4" htmlFor="">
              Add Meeting Notes
            </label>
            <InputBox
              type="date"
              onChange={handleChange}
              label={"Date of Meeting"}
              name="date"
              required
              value={formData.date}
            />

            <JoditEditor ref={editor} value={content} onBlur={handleContent} />
            <button
              type="submit"
              className="bg-leechiuBlue text-white p-2 rounded mt-4 w-48"
            >
              Save
            </button>
          </form>
        </div>
      )}

      <div>
        <h4 className="font-bold mb-4 text-orange-600">Meeting Notes</h4>
        {meetingNotesArranged.map((note) => {
          return (
            <div className="bubble border p-4 mb-4" key={note.id}>
              <div className="flex justify-between">
                <div className="font-bold">{note.date}</div>
                <div>{note.created_by}</div>
              </div>
              <div className="mt-4">{HTMLReactParser(note.notes)}</div>
              <button
                disabled={editMode}
                onClick={(e) => {
                  handleDelete(e, note.id);
                }}
                className="bg-red-500 text-xs text-white p-2 rounded mt-4 disabled:bg-gray-500 disabled:cursor-not-allowed"
              >
                Delete
              </button>
              <button
                className="bg-leechiuBlue text-xs text-white p-2 rounded mt-4 ml-4 disabled:bg-gray-500 disabled:cursor-not-allowed"
                disabled={editMode}
                onClick={(e) => {
                  handleEdit(e, note);
                }}
              >
                Edit
              </button>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
}

export default MeetingNotes;
