import React, { useState, useRef, useEffect } from "react";
import { logOutUser } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import {
  IoMenu,
  IoClose,
  IoNotifications,
  IoPersonCircle,
  IoSearchOutline,
  IoSettingsOutline,
  IoLogOutOutline,
  IoChevronDown,
} from "react-icons/io5";
import Sidebar from "./Sidebar";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import NoProfilePhoto from "../../assets/blankprofile.webp";

export default function Header() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const profileRef = useRef(null);
  const notificationRef = useRef(null);

  // Get user's profile photo or default to a blank one
  const profilePhoto = currentUser.profilePhoto || NoProfilePhoto;

  const handleLogout = () => {
    logOutUser();
    navigate("/login");
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setProfileOpen(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setNotificationsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="bg-leechiuBlue h-16 flex items-center justify-between px-4 md:px-8 w-full sticky top-0 z-10">
      <div className="flex items-center">
        <button
          className="md:hidden text-2xl text-white mr-4 focus:outline-none"
          onClick={() => setViewMode(!viewMode)}
        >
          <IoMenu />
        </button>
        {/* <h1 className="text-white font-bold text-lg hidden md:block">
          Dashboard
        </h1> */}
      </div>

      <div className="bg-white/20 rounded-full px-4 py-2 flex items-center max-w-md w-full mx-4 hidden md:flex">
        <IoSearchOutline className="text-white/70 mr-2" />
        <input
          type="text"
          placeholder="Search..."
          className="bg-transparent border-none outline-none text-white placeholder-white/70 w-full text-sm"
        />
      </div>

      <div className="flex items-center gap-3">
        <div className="relative" ref={notificationRef}>
          <button
            className="text-white p-2 rounded-full hover:bg-white/10 transition-colors relative"
            onClick={() => setNotificationsOpen(!notificationsOpen)}
          >
            <IoNotifications className="text-xl" />
            <span className="absolute top-1 right-1 bg-leechiuOrange rounded-full w-2 h-2 animate-pulse"></span>
          </button>

          {notificationsOpen && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl py-2 z-20"
            >
              <h3 className="px-4 py-2 text-sm font-semibold border-b">
                Notifications
              </h3>
              <div className="max-h-80 overflow-y-auto">
                <div className="px-4 py-3 hover:bg-gray-50 border-b border-gray-100">
                  <p className="text-sm font-medium">New message received</p>
                  <p className="text-xs text-gray-500">5 minutes ago</p>
                </div>
                <div className="px-4 py-3 hover:bg-gray-50">
                  <p className="text-sm font-medium">System update completed</p>
                  <p className="text-xs text-gray-500">1 hour ago</p>
                </div>
              </div>
              <div className="border-t border-gray-100 mt-1 pt-1">
                <button className="w-full text-center text-xs text-leechiuBlue py-2 hover:bg-gray-50">
                  View all notifications
                </button>
              </div>
            </motion.div>
          )}
        </div>

        <div className="relative" ref={profileRef}>
          <button
            className="text-white hover:bg-white/10 transition-colors flex items-center gap-2 p-2 rounded-full md:rounded-lg"
            onClick={() => setProfileOpen(!profileOpen)}
          >
            <div className="h-8 w-8 rounded-full bg-white/20 flex items-center justify-center overflow-hidden">
              {profilePhoto ? (
                <img
                  src={profilePhoto}
                  alt={currentUser.displayName || "User"}
                  className="h-full w-full object-cover"
                />
              ) : (
                <IoPersonCircle className="text-xl text-white" />
              )}
            </div>
            <span className="hidden md:block text-sm font-medium text-white">
              {currentUser.displayName || "User"}
            </span>
            <IoChevronDown
              className={`hidden md:block text-sm transition-transform ${
                profileOpen ? "rotate-180" : ""
              }`}
            />
          </button>

          {profileOpen && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-xl py-2 z-20"
            >
              <div className="px-4 py-2 border-b border-gray-100">
                <p className="text-sm font-medium">
                  {currentUser.displayName || "User"}
                </p>
                <p className="text-xs text-gray-500">
                  {currentUser.email || ""}
                </p>
                {currentUser.department && (
                  <p className="text-xs text-gray-500 mt-1">
                    {currentUser.department}
                  </p>
                )}
              </div>
              <a
                href="#profile"
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <IoPersonCircle className="mr-2 text-leechiuBlue" />
                Your Profile
              </a>
              <a
                href="#settings"
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              >
                <IoSettingsOutline className="mr-2 text-leechiuBlue" />
                Settings
              </a>
              <div className="border-t border-gray-100 my-1"></div>
              <button
                onClick={handleLogout}
                className="w-full text-left flex items-center px-4 py-2 text-sm text-leechiuOrange hover:bg-gray-50"
              >
                <IoLogOutOutline className="mr-2" />
                Log Out
              </button>
            </motion.div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {viewMode && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 md:hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setViewMode(false)}
          >
            <motion.div
              className="bg-white h-full w-72 relative"
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ type: "tween" }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="bg-leechiuBlue p-4 flex items-center justify-between">
                <h2 className="text-white font-bold">Dashboard</h2>
                <button
                  className="text-xl text-white/80 hover:text-white"
                  onClick={() => setViewMode(false)}
                >
                  <IoClose />
                </button>
              </div>
              <div>
                <Sidebar />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
