import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getAllDocsSnap,
  getDocDataSnap,
  setDocData,
} from "../../Firebase/Firebase.utils";
import InputBox from "../../components/InputBox";
import { current } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { parse } from "uuid";

export default function Comparables() {
  const { id } = useParams();
  const [comparables, setComparables] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [originalComparables, setOriginalComparables] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchComparables = async () => {
      await getDocDataSnap("listings", id, (snap) => {
        setComparables(snap.comparables || []);
        setOriginalComparables(snap.comparables || []);
        setIsLoading(false);
      });
    };
    fetchComparables();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  const handleSave = async () => {
    setIsProcessing(true);
    const newComparables = [...comparables, formData];
    await setDocData("listings", id, { comparables: newComparables });
    toast.success("Comparable successfully saved");
    setFormData({});
    setIsProcessing(false);
  };

  const handleEditMode = async () => {
    if (editMode) {
      await setDocData("listings", id, { comparables });
      toast.success("Comparables successfully saved");
      setFormData({});
    }
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    setEditing(true);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRemove = (index) => {
    if (window.confirm("Are you sure you want to remove this comparable?")) {
      setEditing(true);
      const newList = comparables.filter((item, i) => i !== index);
      setComparables(newList);
    }
  };

  return (
    <div>
      <div className="header">Comparables</div>
      <button className="blue-button" onClick={handleEditMode}>
        {editMode ? "Close Edit Mode" : "Edit"}
      </button>
      <div>
        <div className="mt-4">
          <div className="mt-4">
            <div>
              <table className="bg-white py-8 px-4 rounded-lg shadow-lg w-full">
                <thead>
                  <tr className="text-leechiuBlue">
                    <th className="w-48 text-left px-2 py-1 capitalize border border-gray-300">
                      Description
                    </th>
                    <th className="w-48 text-left px-2 py-1  border border-gray-300">
                      Current Use
                    </th>
                    <th className="w-48 text-right px-2 py-1  border border-gray-300">
                      Price per sqm
                    </th>
                    <th className="w-48 text-right px-2 py-1  border border-gray-300">
                      Area in sqm
                    </th>
                    <th className="w-48 text-right px-2 py-1  border border-gray-300">
                      Total Asking Price
                    </th>
                    <th className="w-48 text-left px-2 py-1 border border-gray-300">
                      Link
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {comparables.map((item, index) => (
                    <tr key={index}>
                      <td className="w-48 text-left px-2 py-1 border border-gray-300 capitalize">
                        {item.description?.toLowerCase()}
                      </td>
                      <td className="w-48 text-left px-2 py-1 border border-gray-300">
                        {item.currentUse}
                      </td>
                      <td className="w-48 px-2 py-1 text-right border border-gray-300">
                        {parseFloat(item.pricePerSqm).toLocaleString()}
                      </td>
                      <td className="w-48 px-2 py-1 text-right border border-gray-300">
                        {parseFloat(item.area).toLocaleString()}
                      </td>
                      <td className="w-48 px-2 py-1 text-right border border-gray-300">
                        {parseFloat(item.totalAskingPrice).toLocaleString()}
                      </td>
                      <td className="w-48 text-left px-2 py-1 underline border border-gray-300">
                        <a href={item.link} target="_blank">
                          {item.link}
                        </a>
                      </td>
                      {editMode && (
                        <button
                          className="px-2 py-1 underline"
                          onClick={() => handleRemove(index)}
                        >
                          Remove
                        </button>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {editMode && (
            <div className="mt-8">
              <div className="flex items-center gap-4">
                <div className="font-bold w-48">Description</div>
                <div className="font-bold w-48">Current Use</div>
                <div className="font-bold w-48">Price per sqm</div>
                <div className="font-bold w-48">Area in sqm</div>
                <div className="font-bold w-48">Total Asking Price</div>
                <div className="font-bold w-48">Link</div>
              </div>

              <div className="mt-4">
                <div className="flex items-center">
                  <input
                    type="text"
                    name="description"
                    placeholder="Property Description"
                    value={formData.description}
                    className={`w-48  border-gray-100 rounded-lg px-2 py-1 ${
                      editMode ? "border text-black" : ""
                    }`}
                    disabled={!editMode}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="currentUse"
                    placeholder="Current Use"
                    value={formData.currentUse}
                    className={`w-48  border-gray-100 rounded-lg px-2 py-1 ${
                      editMode ? "border text-black" : ""
                    }`}
                    disabled={!editMode}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="pricePerSqm"
                    placeholder="Price per sqm"
                    value={formData.pricePerSqm}
                    className={`w-48  border-gray-100 rounded-lg px-2 py-1 ${
                      editMode ? "border text-black" : ""
                    }`}
                    disabled={!editMode}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="area"
                    placeholder="Area in sqm"
                    value={formData.area}
                    className={`w-48  border-gray-100 rounded-lg px-2 py-1 ${
                      editMode ? "border text-black" : ""
                    }`}
                    disabled={!editMode}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="totalAskingPrice"
                    placeholder="Total Asking Price"
                    value={formData.totalAskingPrice}
                    className={`w-48  border-gray-100 rounded-lg px-2 py-1 ${
                      editMode ? "border text-black" : ""
                    }`}
                    disabled={!editMode}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="link"
                    placeholder="Website link"
                    value={formData.link}
                    className={`w-48  border-gray-100 rounded-lg px-2 py-1 ${
                      editMode ? "border text-black" : ""
                    }`}
                    disabled={!editMode}
                    onChange={handleChange}
                  />

                  <button className="blue-button" onClick={handleSave}>
                    {isProcessing ? "Processing" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
