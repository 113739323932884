import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { queryAllDocsByField } from "../../Firebase/Firebase.utils";
import { useNavigate } from "react-router-dom";
import TimelineIcon from "@mui/icons-material/Timeline";

export default function LeasingAccountCountCard({ data }) {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [leasingAccounts, setLeasingAccounts] = React.useState([]);
  const [landLordRepAccounts, setLandLordRepAccounts] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const potentialFee = data.reduce((acc, curr) => {
    const fee = parseFloat(curr.potentialFee);
    return acc + (isNaN(fee) ? 0 : fee);
  }, 0);

  const handleNavigate = () => {
    navigate("/leasing");
  };

  return (
    <div
      className="bg-white w-48 h-24  p-2 rounded-lg shadow-md cursor-pointer"
      onClick={handleNavigate}
    >
      <div>
        <div className="flex items-center gap-4 mb-2">
          <div>
            <TimelineIcon className="text-leechiuBlue" />
          </div>
          <div className="text-xs text-gray-400">Potential Fees</div>
        </div>
        <span>Php </span>
        <span className="text-2xl font-bold text-leechiuBlue">
          {(potentialFee / 1000000).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          M
        </span>
      </div>
      <div className="text-leechiuOrange text-xs font-bold">
        {data.length} Accounts
      </div>
    </div>
  );
}
